import React from 'react';
import logo from './logo.svg';
import './App.css';
import { PriceResponse } from '../functions/lib/price_getter';

class Price extends React.Component<{ coin: string; alias?: string }, { price: number }> {
  constructor(props: any) {
    super(props);
    this.state = {
      price: -1
    }
  }

  async get(): Promise<PriceResponse> {
    return await fetch(window.location.origin + "/api/price/?coin=" + this.props.coin).then(res => res.json());
  }

  componentDidMount() {
    this.get().then(res => parseFloat(res.wonderland.usd)).then(price => this.setState({ price }));
  }

  render() {
    return (
      <div>
        <p>Current ${(this.props.alias || this.props.coin).toUpperCase()} price: ${this.state.price < 0.001 ? this.state.price : this.state.price.toLocaleString()}</p>
      </div>
    )
  }
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Price coin='wonderland' alias='TIME' />
        <img src={'https://scontent.fhfa2-2.fna.fbcdn.net/v/t1.18169-9/11081045_10202733378336113_1555036116911952704_n.jpg?_nc_cat=102&cb=c578a115-2e46c7d2&ccb=1-5&_nc_sid=a9b1d2&_nc_ohc=aG-LfhfeqJEAX_ny6X_&_nc_ht=scontent.fhfa2-2.fna&oh=00_AT_8jNSxBwjoaF9PpLPmmnC_lCdmFTV2IBPtEeXFJ9e60g&oe=61E7DA25'} />
        <p>
          When Ron was an ambitious 20 years old man...
        </p>
      </header>
    </div >
  );
}

export default App;
